<template>
  <div>
    <headerSection :showXPBXLogo="true" />
    <section class="flex flex-col md:flex-row flex-wrap page-content">
      <sidebar />
      <main class="flex-1 overflow-y-hidden">
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script>
import sidebar from "@/modules/xpbx/components/home/sidebar/index";
import headerSection from "@/modules/common/components/header-section/index";

export default {
  name: "Index",
  components: {
    sidebar,
    headerSection,
  },
};
</script>

<style scoped lang="scss">
.page-content {
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
}
.sidebar {
  .main {
    ul {
      max-height: 100%;
      height: 100%;
      & > li {
        padding: 0.65rem 0.5rem;
        vertical-align: middle;
        &:not(:first-of-type) {
          margin-top: 2px;
        }
        a {
          padding: 0 0.5rem;
        }
      }
    }
  }
}
</style>
