<template>
  <div>
    <!-- <input ref="input" type="text" @focus="showCalendar" tabindex="-1" /> -->
    <div
      v-if="!isCalendarVisible"
      class="h-10 w-full bg-white border border-gray-300 rounded-md flex items-center cursor-pointer pl-2 start-start-calendar"
      @click="showCalendar"
    >
      {{ formattedDate }}
    </div>
    <Calendar
      ref="calendar"
      v-if="isCalendarVisible"
      v-model="date"
      showTime
      dateFormat="yy-mm-dd"
      placeholder="yyyy-mm-dd"
    ></Calendar>
  </div>
</template>

<script>
import { ref, nextTick, computed, watch } from "vue";
import Calendar from "primevue/calendar";

export default {
  name: "BaseCalendar",

  components: {
    Calendar,
  },

  props: {
    keyName: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    },
  },

  setup(props, { emit }) {
    const calendar = ref(null);
    const isCalendarVisible = ref(false);
    const date = ref(props.modelValue ? new Date(props.modelValue) : null);

    const formattedDate = computed(() => {
      if (date?.value) {
        return formatDateWIthHours(date.value);
      }

      return "";
    });

    const formatDateWIthHours = (date) => {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = +month > 9 ? month : `0${month}`;
      let day = date.getDate();
      day = +day > 9 ? day : `0${day}`;
      let hours = date.getHours();
      hours = +hours > 9 ? hours : `0${hours}`;
      let minutes = date.getMinutes();
      minutes = +minutes > 9 ? minutes : `0${minutes}`;
      let seconds = date.getSeconds();
      seconds = +seconds > 9 ? seconds : `0${seconds}`;

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const showCalendar = async () => {
      isCalendarVisible.value = true;

      if (!props.modelValue) {
        if (props.keyName === "ss-date" || props.keyName === "es-date") {
          const startDate = new Date();
          startDate.setHours(0, 0, 0, 0);
          date.value = startDate;
        } else {
          const endDate = new Date();
          // endDate.setHours(23, 59, 59, 999);
          endDate.setHours(0, 0, 0, 0);
          date.value = endDate;
        }
      }

      await nextTick();
      calendar.value.$el.querySelector("input").focus();
    };

    const hideCalendar = () => {
      isCalendarVisible.value = false;
    };

    watch(
      () => date.value,
      async (newValue) => {
        emit("update", { value: newValue, key: props.keyName });
      }
    );

    return {
      date,
      calendar,
      formattedDate,
      isCalendarVisible,

      showCalendar,
      hideCalendar,
      formatDateWIthHours,
    };
  },
};
</script>

<style>
</style>