<template>
  <div class="bg-white w-full pt-5">
    <!-- Datatable -->
    <div class="card relative table-wrapper">
      <DataTable
        ref="dt"
        :value="messageReports"
        dataKey="id"
        scrollable
        removableSort
        sortMode="multiple"
        :loading="loading"
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        filterDisplay="menu"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        :globalFilterFields="['msgtype']"
      >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div class="flex-1">
              <Paginator
                :rows="rows"
                v-model:first="currentPage"
                ref="smsPaginator"
                @page="onPage($event)"
                :totalRecords="totalRecords"
                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :rowsPerPageOptions="[10, 20, 30]"
              />
            </div>
            <div class="flex gap-2 items-center">
              <IconField iconPosition="left" class="table-search">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
              </IconField>
              <Export
                :dt="dt"
                :tableData="messageReports"
                :columns="SMSReportsHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          field="msgtype"
          :header="$t('xpbx.table-field.direction')"
          sortable
          style="width: 20%"
        >
          <template #body="{ data }">
            {{ $t("xpbx.pages.dashboard.texts." + data.msgtype) }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              v-model="filterModel.value"
              :data-name="filterModel"
              type="text"
              class="p-column-filter"
              placeholder="Select a direction"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('msgtype', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="calling_number"
          :header="$t('xpbx.table-field.dialingNumber')"
          sortable
          style="width: 20%"
        >
          <template #body="{ data }">
            {{ data?.calling_number }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('xpbx.table-field.dialingNumber')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('calling_number', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="called_number"
          sortable
          :header="$t('xpbx.table-field.dialedNumber')"
          style="width: 15%"
        >
          <template #body="slotProps">
            {{ slotProps?.data?.called_number }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              v-model="filterModel.value"
              type="text"
              class="p-column-filter table-filter"
              :placeholder="$t('xpbx.table-field.dialedNumber')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('called_number', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="msgstatus"
          filterField="msgstatus"
          sortable
          :header="$t('xpbx.table-field.result')"
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data.msgstatus }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('xpbx.table-field.result')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('msgstatus', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="client_cost"
          :header="$t('xpbx.table-field.amount')"
          sortable
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data.client_cost }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('xpbx.table-field.amount')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('client_cost', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="created_at"
          sortable
          :header="$t('xpbx.table-field.date')"
          style="width: 15%"
        >
          <template #body="{ data }">
            <DateItem :value="data?.created_at" />
          </template>
          <!-- <template #filter="{ filterModel }">
            <InputText
              v-model="filterModel.value"
              type="text"
              class="p-column-filter table-filter"
              :placeholder="$t('xpbx.placeholders.timezone')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('timezone', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template> -->
        </Column>
      </DataTable>
      <!-- <Loader v-else /> -->
    </div>
    <!-- Call reports table -->
    <!-- <section class="flex flex-row flex-wrap w-full">
      <div
        class="w-full mx-auto overflow-x-auto scrollable-table-wrapper scrollable-reports-sms-table"
      >
        <table
          class="w-full border-2 border-t-0 collapse border-solid m-0 b-0 table-fixed"
          v-if="messageReports && messageReports?.length"
        >
          <thead class="">
            <tr class="text-app-purple text-lg">
              <th scope="col" class="py-3 text-left pl-2">
                {{ $t("xpbx.table-field.direction") }}
              </th>
              <th scope="col" class="text-left">
                {{ $t("xpbx.table-field.dialingNumber") }}
              </th>
              <th scope="col" class="text-left">
                {{ $t("xpbx.table-field.dialedNumber") }}
              </th>
              <th scope="col" class="text-left">
                {{ $t("xpbx.table-field.date") }}
              </th>
              <th scope="col font-bold" class="text-left">
                {{ $t("xpbx.table-field.result") }}
              </th>
              <th scope="col" class="text-left">
                {{ $t("xpbx.table-field.amount") }}
              </th>
            </tr>
          </thead>
         
          <tbody>
            <tr
              class="border-b-2 border-dotted"
              v-for="(item, index) in messageReports"
              :key="index"
            >
              <td data-label="Direction" class="text-left pl-2">
                {{ $t("xpbx.pages.dashboard.texts." + item.msgtype) }}
              </td>
              <td data-label="Dialing number" class="capitalize py-2 text-left">
                {{ item.calling_number }}
              </td>
              <td data-label="Dialed number" class="text-left">
                {{ item.called_number }}
              </td>
              <td data-label="Start" class="text-left">
                <DateItem :value="item.created_at" />
              </td>
              <td data-label="Result from call" class="text-left">
                {{ item.msgstatus }}
              </td>
              <td data-label="Amount" class="text-left">
                {{ item.client_cost }}
              </td>
            </tr>
          </tbody>
        </table>
        <Loader v-else />
      </div>
    </section> -->
  </div>
</template>

<script>
import { onMounted, inject, ref, watch, computed } from "vue";
import useReports from "@/modules/xpbx/composables/useReports";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
// Datatable components
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Calendar from "primevue/calendar";
import Paginator from "primevue/paginator";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import useTableFilters from "@/modules/xpbx/composables/useTableFilters";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { SMSReportsHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import { set } from "lodash";

export default {
  name: "SMSTable",

  components: {
    Loader,
    DateItem,
    // Datatable
    DataTable,
    Column,
    Button,
    IconField,
    InputIcon,
    Calendar,
    InputText,
    Paginator,
    Export,
  },

  setup() {
    const { findMessageReports, messageReports, totalRecords } = useReports();
    // Datatable variables
    const dt = ref();
    const loading = ref(false);
    const rows = ref(10);
    const currentPage = ref(0);
    const scrollHeight = ref(window.innerHeight - 350);
    const selectedRecords = ref([]);
    const lazyParams = ref({});
    const smsPaginator = ref(null);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // username: { value: null, matchMode: FilterMatchMode.CONTAINS },
      msgtype: {
        // operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      calling_number: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      called_number: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      msgstatus: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      client_cost: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    const { getFilters, resetFilters } = useTableFilters();

    const loadLazyData = async (filters) => {
      const cPage = filters?.paging?.page || 1;

      loading.value = true;

      if (lazyParams.value.filters) {
        localStorage.setItem(
          "smsFiltersData",
          JSON.stringify(lazyParams.value)
        );
      }

      currentPage.value = (cPage - 1) * rows.value;

      await findMessageReports(filters);

      loading.value = false;
    };

    const onPage = async (event) => {
      rows.value = event.rows;
      localStorage.setItem("smsOnPage", JSON.stringify(event));
      const filters = getFilters(lazyParams.value, event);
      await loadLazyData(filters);
    };

    const onSort = async (event) => {
      lazyParams.value = event;

      const filters = getFilters(lazyParams.value);
      // Make API request
      await loadLazyData(filters);
    };

    const onFilter = (event) => {
      lazyParams.value.filters = filters.value;
    };

    const filterSearch = async (fn) => {
      const filters = getFilters(lazyParams.value);
      await loadLazyData(filters);
      fn();
    };

    const clearFilters = async (field, fn) => {
      const newFilters = resetFilters({ ...filters.value }, field);

      filters.value = newFilters;
      lazyParams.value.filters = newFilters;
      const filtersData = getFilters(lazyParams.value);
      // Make API request
      await loadLazyData(filtersData);
      fn();
    };

    // End Filters sort page methods
    const getDefaults = async () => {
      const smsFilters = localStorage.getItem("smsFiltersData");

      let defaultFilters = {
        filter: [],
        sort: [],
        paging: { page: 1, records: 10 },
      };

      if (smsFilters) {
        const filtersData = JSON.parse(smsFilters);

        if (filtersData?.filters) {
          lazyParams.value = filtersData;
          filters.value = filtersData.filters;

          defaultFilters = getFilters(filtersData);
        }
      }

      const smsOnPageData = localStorage.getItem("smsOnPage");

      if (smsOnPageData) {
        const pageData = JSON.parse(smsOnPageData);

        defaultFilters.paging.page = pageData.page + 1;
        defaultFilters.paging.records = pageData.rows;
        rows.value = pageData.rows;
      }

      await loadLazyData(defaultFilters);
    };

    onMounted(async () => {
      await getDefaults();
    });

    return {
      dt,
      rows,
      filters,
      loading,
      currentPage,
      smsPaginator,
      totalRecords,
      lazyParams,
      scrollHeight,
      SMSReportsHeaders,
      selectedRecords,

      // Methods
      onSort,
      onPage,
      onFilter,
      loadLazyData,
      filterSearch,
      getDefaults,
      clearFilters,
      messageReports,
    };
  },
};
</script>
