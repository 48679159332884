import { ref } from "vue";
import api from "@/services/userApi";
import { FilterMatchMode } from "primevue/api";

export default function useReports() {
  const callReports = ref([]);
  const totalRecords = ref(0);
  const totalMessages = ref(0);
  const messageReports = ref([]);
  const calltypesOptions = ref([]);
  const callsFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    calltype: {
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    calling_number: {
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    called_number: {
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    dialstatus: {
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    duration: {
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    client_cost: {
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    callstart: {
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    callstop: {
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });

  //   FIND AND FILTER CALL REPORTS
  const findCallReports = async (
    filters = {
      filter: [],
      sort: [],
      paging: {
        page: 1,
        records: 10,
      },
      calltype: "inc",
    },
    url = ""
  ) => {
    try {
      let requestUrl = `/admin/xpbx/report/calls`;

      if (url) requestUrl = `${requestUrl}/${url}`;

      const response = await api.post(requestUrl, filters);

      if (response?.data) {
        callReports.value = response.data;
        totalRecords.value = response.data.total;

        if (response.data?.calltypes?.length) {
          const calltypes = response.data?.calltypes.map((item) => {
            return { name: getCalltypeValue(item), code: item };
          });

          calltypesOptions.value = calltypes;
        }
      } else {
        totalRecords.value = 0;
        callReports.value = [];
      }
    } catch (error) {
      console.log(error);
      totalRecords.value = 0;
      callReports.value = [];
    }
  };

  //   FIND AND FILTER MESSAGE REPORTS
  const findMessageReports = async (filters = {}) => {
    try {
      const response = await api.post("/admin/xpbx/report/msgs", filters);

      if (response?.data && response?.data) {
        totalRecords.value = response.data?.total;
        messageReports.value = response.data?.records;
      } else {
        totalRecords.value = 0;
        messageReports.value = [];
      }
    } catch (error) {
      console.log(error);
      totalRecords.value = 0;
      messageReports.value = [];
    }
  };

  const getCalltypeValue = (code) => {
    switch (code) {
      case "xlinx-out":
        return "Xlinx out";
      case "ccs-out":
        return "Ccs out";
      case "ivr-out":
        return "Ivr out";
      case "ivr-inc":
        return "Ivr inc";
      default:
        return "";
    }
  };

  const getDate = (date) => {
    const d = new Date(date);
    const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
    const month =
      d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
    return `${d.getFullYear()}-${month}-${day}`;
  };

  return {
    callsFilters,
    callReports,
    totalRecords,
    totalMessages,
    messageReports,
    calltypesOptions,
    findCallReports,
    findMessageReports,
  };
}
