<template>
  <div class="tabs flex mt-2">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      class="cursor-pointer flex-1 tab"
      :class="{ active: activeTab === tab.id }"
      @click="tabHandle(tab.id)"
    >
      <p>{{ tab.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
      default: 1,
    },
  },

  setup(props, { emit }) {
    const tabHandle = (id) => {
      emit("tabChange", id);
    };

    return {
      tabHandle,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab {
  border: 1px solid #e5e5e5;
  p {
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #a0a0a0;
  }
}

.tab.active {
  border: 1px solid #9b9bc5;
  p {
    color: #000;
  }
}
</style>