<template>
  <div class="bg-white w-full pt-3 xxl:pt-5">
    <div class="pb-5">
      <Tabs :tabs="tabs" :activeTab="activeTab" @tabChange="tabChangeHandle" />
    </div>

    <!-- Datatable -->
    <div></div>
    <div class="card relative table-wrapper">
      <DataTable
        ref="dt"
        :value="reports"
        dataKey="id"
        scrollable
        removableSort
        sortMode="multiple"
        :loading="loading"
        filterDisplay="menu"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        :scrollHeight="`${scrollHeight}px`"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        :globalFilterFields="['msgtype']"
      >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div class="flex-1">
              <Paginator
                :rows="rows"
                class="custom-paginator"
                ref="paginator"
                @page="onPage($event)"
                v-model:first="currentPage"
                :totalRecords="totalRecords"
                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :rowsPerPageOptions="[10, 20, 30]"
              />
            </div>
            <div class="flex gap-2 items-center">
              <IconField iconPosition="left" class="table-search">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
              </IconField>
              <Export
                :dt="dt"
                :tableData="reports"
                :columns="CallsReportsHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          filterField="calltype"
          :header="$t('xpbx.table-field.direction')"
          :showFilterMatchModes="false"
          :filterMenuStyle="{ width: '14rem' }"
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <div class="flex align-items-center gap-2">
              {{ $t("xpbx.pages.dashboard.texts." + data.calltype) }}
            </div>
          </template>
          <template #filter="{ filterModel }" v-if="showCalltypeFilter">
            <Dropdown
              v-model="filterModel.value"
              :options="calltypesOptions"
              optionLabel="name"
              placeholder="Select a direction"
              class="w-full md:w-14rem"
            />
          </template>
          <template #filterclear="{ filterCallback }" v-if="showCalltypeFilter">
            <Button
              type="button"
              @click="clearFilters('calltype', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }" v-if="showCalltypeFilter">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="calling_number"
          :header="$t('xpbx.table-field.dialingNumber')"
          sortable
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data?.calling_number }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('xpbx.table-field.dialingNumber')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('calling_number', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="called_number"
          sortable
          :header="$t('xpbx.table-field.dialedNumber')"
          style="width: 15%"
        >
          <template #body="slotProps">
            {{ slotProps?.data?.called_number }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              v-model="filterModel.value"
              type="text"
              class="p-column-filter table-filter"
              :placeholder="$t('xpbx.table-field.dialedNumber')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('called_number', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>

        <Column
          field="dialstatus"
          filterField="dialstatus"
          sortable
          :showFilterMatchModes="false"
          :header="$t('xpbx.table-field.result_call')"
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ getDialedStatus(data.dialstatus) }}
          </template>
          <!-- dialstatuses -->
          <template #filter="{ filterModel }">
            <Dropdown
              v-model="filterModel.value"
              :options="dialstatuses"
              optionLabel="name"
              placeholder="Select a status"
              class="w-full md:w-14rem"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('dialstatus', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="duration"
          :header="$t('xpbx.table-field.duration')"
          sortable
          style="width: 10%"
        >
          <template #body="{ data }">
            {{ data.duration }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('xpbx.table-field.duration')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('duration', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="client_cost"
          :header="$t('xpbx.table-field.amount')"
          sortable
          style="width: 10%"
        >
          <template #body="{ data }">
            {{ data.client_cost }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('xpbx.table-field.amount')"
            />
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('client_cost', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterSearch(filterCallback)"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="callstart"
          sortable
          :showFilterMatchModes="false"
          :header="$t('xpbx.table-field.start')"
          style="width: 10%"
        >
          <template #body="{ data }">
            <DateItem :value="data?.callstart" />
          </template>
          <template #filter="{}">
            <div class="calendar-wrapper w-56">
              <label for="calendar-12h" class="font-bold block mb-2">
                {{ $t("xpbx.labels.start-date.start") }}
              </label>
              <BaseCalendar
                @update="updateDate"
                :keyName="'ss-date'"
                :modelValue="startDate.start"
              />
              <label for="calendar-12h" class="font-bold block mb-2">
                {{ $t("xpbx.labels.start-date.end") }}
              </label>
              <BaseCalendar
                @update="updateDate"
                :keyName="'se-date'"
                :modelValue="startDate.end"
              />
            </div>
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('callstart', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterDateSearch(filterCallback, 'callstart')"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
        <Column
          field="callstop"
          sortable
          filterField="callstop"
          :showFilterMatchModes="false"
          :header="$t('xpbx.table-field.end')"
          style="width: 10%"
        >
          <template #body="{ data }">
            <DateItem :value="data?.callstop" />
          </template>
          <template #filter="{}">
            <div class="calendar-wrapper w-56">
              <label for="calendar-12h" class="font-bold block mb-2">
                {{ $t("xpbx.labels.end-date.start") }}
              </label>
              <BaseCalendar
                @update="updateDate"
                :keyName="'es-date'"
                :modelValue="endDate.start"
              />
              <!-- <Calendar
                v-model="endDate.start"
                showTime
                class="my-2"
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
              /> -->
              <label for="calendar-12h" class="font-bold block mb-2">
                {{ $t("xpbx.labels.end-date.end") }}
              </label>
              <BaseCalendar
                @update="updateDate"
                :keyName="'ee-date'"
                :modelValue="endDate.end"
              />
            </div>
          </template>
          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              @click="clearFilters('callstop', filterCallback)"
              severity="secondary"
              >{{ $t("xpbx.button.clear") }}</Button
            >
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              @click="filterDateSearch(filterCallback, 'callstop')"
              severity="success"
              >{{ $t("xpbx.button.apply") }}</Button
            >
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, computed, watch } from "vue";
import Tabs from "@/modules/xpbx/components/UI/tabs/index.vue";
import useReports from "@/modules/xpbx/composables/useReports";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
// Datatable components
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Paginator from "primevue/paginator";
import MultiSelect from "primevue/multiselect";
// import { FilterMatchMode, FilterOperator } from "primevue/api";
import useTableFilters from "@/modules/xpbx/composables/useTableFilters";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { CallsReportsHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import BaseCalendar from "@/modules/xpbx/components/reports/calls-table/components/BaseCalendar.vue";

export default {
  name: "CallsTable",

  props: {
    updateId: {
      type: Number,
      required: true,
    },
  },

  components: {
    Tabs,
    DateItem,
    DataTable,
    Column,
    Button,
    InputIcon,
    InputText,
    IconField,
    Calendar,
    Export,
    Paginator,
    MultiSelect,
    Dropdown,
    BaseCalendar,
  },

  setup(props, { emit }) {
    const t = inject("t");
    const activeTab = ref(0);
    const tabsData = ref(["inc", "out", "miss-inc", "miss-out"]);
    const tabsDataValues = ref(["inc", "out", "miss", "miss-out"]);
    const reports = ref([]);
    const startDate = ref({
      start: null,
      end: null,
    });
    const endDate = ref({
      start: null,
      end: null,
    });

    const {
      findCallReports,
      callReports,
      totalRecords,
      callsFilters,
      calltypesOptions,
    } = useReports();
    // Datatable variables
    const dt = ref();
    const loading = ref(false);
    const paginator = ref(null);
    const rows = ref(10);
    const currentPage = ref(0);
    const dialstatuses = ref([]);
    const scrollHeight = ref(window.innerHeight - 350);
    const selectedRecords = ref([]);
    const lazyParams = ref({});
    const filters = ref(callsFilters.value);
    const { getFilters, resetFilters } = useTableFilters();

    const showCalltypeFilter = computed(() => {
      if (
        activeTab.value === 0 ||
        activeTab.value === 1 ||
        activeTab.value === 2
      )
        return true;
      return false;
    });

    const getCallsType = (type) => {
      switch (type) {
        case "inc":
          return "inc";
        case "out":
          return "out";
        case "miss-inc":
          return "miss";
        case "miss-out":
          return "miss-out";
        default:
          return "";
      }
    };

    const getCallType = (type) => {
      switch (type) {
        case "ipa-out":
          return t("xpbx.pages.dashboard.texts.ipa-out");
        case "ivr-out":
          return t("xpbx.pages.dashboard.texts.ivr-out");
        case "pbx-out":
          return t("xpbx.pages.dashboard.texts.pbx-out");
        case "ccs-out":
          return t("xpbx.pages.dashboard.texts.ccs-out");
        case "xlinx-out":
          return t("xpbx.pages.dashboard.texts.xlinx-out");
        case "ivr-inc":
          return t("xpbx.pages.dashboard.texts.ivr-inc");
        case "miss-out":
          return t("xpbx.pages.dashboard.texts.miss-out");
        case "miss-inc":
          return t("xpbx.pages.dashboard.texts.miss-inc");
        case "sms-out":
          return t("xpbx.pages.dashboard.texts.sms-out");
        case "sms-inc":
          return t("xpbx.pages.dashboard.texts.sms-inc");
        default:
          return "";
      }
    };

    const getStatus = (status) => {
      switch (status) {
        case "ANSWER":
          return t("xpbx.pages.dashboard.texts.answer");
        case "BUSY":
          return t("xpbx.pages.dashboard.texts.busy");
        case "NOANSWER":
          return t("xpbx.pages.dashboard.texts.no_answer");
        case "CANCEL":
          return t("xpbx.pages.dashboard.texts.cancel");
        case "CONGESTION":
          return t("xpbx.pages.dashboard.texts.congestion");
        case "CHANUNAVAIL":
          return t("xpbx.pages.dashboard.texts.chanunavail");
        default:
          return "";
      }
    };

    const loadLazyData = async (filters) => {
      const calltypeField = filters?.filter.find(
        (item) => item.field === "calltype"
      );
      const cPage = filters?.paging?.page || 1;

      const calltype = getCallsType(tabsData.value[activeTab.value - 1]);

      if (!calltypeField) {
        // filters.filter.push({
        //   field: "calltype",
        //   match: "in",
        //   value: [calltype],
        // });
      }

      const isDate = filters?.filter.find((item) => item.field === "callstart");

      if (isDate) {
        const countDates = isDate?.value?.length;

        if (countDates === 1) {
          return;
        }
      } else {
        const dates = getDefaultDates();
        // filters.filter.push({
        //   field: "callstart",
        //   match: "btw",
        //   value: dates,
        // });
      }

      loading.value = true;

      if (lazyParams.value.filters) {
        localStorage.setItem(
          "callsFiltersData",
          JSON.stringify(lazyParams.value)
        );
      }

      if (calltype) localStorage.setItem("callsType", calltype);
      currentPage.value = (cPage - 1) * rows.value;

      await findCallReports(filters, calltype);
      flatReportsData();
      loading.value = false;
    };

    const onPage = async (event) => {
      rows.value = event.rows;
      localStorage.setItem("callsOnPage", JSON.stringify(event));
      const filters = getFilters(lazyParams.value, event);
      await loadLazyData(filters);
    };

    const onSort = async (event) => {
      lazyParams.value = event;

      const filters = getFilters(lazyParams.value);
      await loadLazyData(filters);
    };

    const onFilter = (event) => {
      lazyParams.value.filters = filters.value;
    };

    const filterSearch = async (fn) => {
      const filters = getFilters(lazyParams.value);

      await loadLazyData(filters);
      fn();
    };

    const clearFilters = async (field, fn) => {
      let newFilters = resetFilters({ ...filters.value }, field);

      if (field === "callstart") startDate.value = { start: null, end: null };

      if (field === "callstop") endDate.value = { start: null, end: null };

      filters.value = newFilters;
      lazyParams.value.filters = newFilters;
      const filtersData = getFilters(lazyParams.value);
      // Make API request
      await loadLazyData(filtersData);
      fn();
    };

    const getDialedStatus = (status) => {
      const splitedSTatus = status.split("|");
      const keyStatus = splitedSTatus[0];
      switch (keyStatus) {
        case "ANSWER":
          return t("xpbx.pages.dashboard.texts.answer");
        case "BUSY":
          return t("xpbx.pages.dashboard.texts.busy");
        case "NOANSWER":
          return t("xpbx.pages.dashboard.texts.no_answer");
        case "CANCEL":
          return t("xpbx.pages.dashboard.texts.cancel");
        case "CONGESTION":
          return t("xpbx.pages.dashboard.texts.congestion");
        case "CHANUNAVAIL":
          return t("xpbx.pages.dashboard.texts.chanunavail");
        default:
          return "";
      }
    };

    // End Filters sort page methods

    const tabs = [
      {
        id: 1,
        title: t("xpbx.pages.reports.tabs.incoming"),
      },
      {
        id: 2,
        title: t("xpbx.pages.reports.tabs.outgoing"),
      },
      {
        id: 3,
        title: t("xpbx.pages.reports.tabs.missed"),
      },
      {
        id: 4,
        title: t("xpbx.pages.reports.tabs.unhandled"),
      },
    ];

    const tabChangeHandle = async (id) => {
      activeTab.value = id;
      emit("update", id);

      // Remove all saved filters
      localStorage.removeItem("callsFiltersData");
      localStorage.removeItem("callsOnPage");
      lazyParams.value = {};
      filters.value = callsFilters.value;

      await loadLazyData({
        filter: [],
        sort: [],
        paging: {
          page: 1,
          records: 10,
        },
      });
    };

    const flatReportsData = () => {
      reports.value = [];
      let reportsArray = [];
      const statusesArray = [];

      // for (const key in callReports.value) {
      //   // reportsArray = [...reportsArray, ...callReports.value[key]];
      //   reportsArray = [...reportsArray, ...callReports.value[key].records];
      // }

      reportsArray = [...(callReports?.value?.records || [])];

      reportsArray.forEach((item) => {
        const splitedStatus = item.dialstatus.split("|");
        const keyStatus = splitedStatus[0];
        if (!statusesArray.includes(keyStatus)) statusesArray.push(keyStatus);
      });

      const statusesData = statusesArray.map((item) => {
        return { name: getStatus(item), code: item };
      });

      dialstatuses.value = statusesData;

      setTimeout(() => {
        reports.value = reportsArray;
      }, 50);
    };

    const getDefaultDates = () => {
      const today = new Date();
      const currentDate = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - 7));

      let thisMonth = currentDate.getMonth() + 1;
      thisMonth = thisMonth < 10 ? "0" + thisMonth : thisMonth;

      let month = firstDay.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      const fDay = `${firstDay.getFullYear()}-${month}-${
        firstDay.getDate() < 10 ? "0" + firstDay.getDate() : firstDay.getDate()
      }`;

      const lDay = `${currentDate.getFullYear()}-${thisMonth}-${
        currentDate.getDate() < 10
          ? "0" + currentDate.getDate()
          : currentDate.getDate()
      }`;

      return [fDay, lDay];
    };

    const setPagination = (page) => {
      const paginatorElement = document.querySelector(".custom-paginator");

      if (!paginatorElement) return;
      const activePage = paginatorElement.querySelector(
        ".p-paginator-pages .p-highlight"
      );

      const activePageNumber = activePage?.textContent;

      if (+activePageNumber === page) return;

      activePage?.classList.remove("p-highlight");

      const newPage = paginatorElement.querySelector(
        `.p-paginator-pages .p-paginator-page:nth-child(${page + 1})`
      );

      newPage?.classList.add("p-highlight");
    };

    const getDefaultCallsData = async () => {
      activeTab.value = 0;
      localStorage.removeItem("callsFiltersData");
      localStorage.removeItem("callsOnPage");
      localStorage.removeItem("callsType");
      const defaultFilters = {
        filter: [],
        sort: [],
        paging: {
          page: 1,
          records: 10,
        },
      };

      await loadLazyData(defaultFilters);
    };

    const filterDateSearch = async (fn, key) => {
      let start = null;
      let end = null;

      if (key === "callstart") {
        start = startDate.value.start;
        end = startDate.value.end;
      }

      if (key === "callstop") {
        start = endDate.value.start;
        end = endDate.value.end;
      }

      if (!start || !end) return;

      const newFilters = { ...filters.value };
      newFilters[key].constraints = [
        {
          matchMode: "btw",
          value: [start, end],
        },
      ];

      filters.value = { ...newFilters };

      lazyParams.value.filters = filters.value;

      const filtersData = getFilters(lazyParams.value);

      await loadLazyData(filtersData);
      fn();
    };

    const getDefaultCalls = async () => {
      const callsFiltersData = localStorage.getItem("callsFiltersData");
      const callsTypeUrl = localStorage.getItem("callsType") || "";

      if (callsTypeUrl) {
        activeTab.value = tabsDataValues.value.indexOf(callsTypeUrl) + 1;
      }

      let defaultFilters = {
        filter: [],
        sort: [],
        paging: {
          page: 1,
          records: 10,
        },
      };

      const pageLocal = localStorage.getItem("callsOnPage");

      if (callsFiltersData) {
        const filtersData = JSON.parse(callsFiltersData);

        if (filtersData?.filters) {
          lazyParams.value = filtersData;
          filters.value = filtersData.filters;

          const existStart = filtersData.filters["callstart"];

          const existEnd = filtersData.filters["callstop"];

          if (existStart?.constraints?.[0]?.value?.length) {
            startDate.value.start = existStart.constraints[0].value[0];
            startDate.value.end = existStart.constraints[0].value[1];
          }

          if (existEnd?.constraints?.[0]?.value?.length) {
            endDate.value.start = existEnd.constraints[0].value[0];
            endDate.value.end = existEnd.constraints[0].value[1];
          }

          defaultFilters = getFilters(filtersData);
        }
      }

      if (pageLocal) {
        const pageData = JSON.parse(pageLocal);
        if (pageData.page > 0 || pageData.rows > 0) {
          rows.value = pageData.rows;

          defaultFilters.paging.page = pageData.page + 1;
          defaultFilters.paging.records = pageData.rows;
        }
      }
      await loadLazyData(defaultFilters, callsTypeUrl);
    };

    const updateDate = (value) => {
      switch (value.key) {
        case "ss-date":
          startDate.value.start = value.value;
          break;
        case "se-date":
          startDate.value.end = value.value;
          break;
        case "es-date":
          endDate.value.start = value.value;
          break;
        case "ee-date":
          endDate.value.end = value.value;
          break;

        default:
          break;
      }
    };

    watch(
      () => props.updateId,
      async (newValue) => {
        if (newValue === 0) {
          activeTab.value = 0;
          // Reset the table
          await getDefaultCallsData();
        }
      }
    );

    onMounted(async () => {
      await getDefaultCalls();
    });

    return {
      dt,
      rows,
      filters,
      loading,
      paginator,
      currentPage,
      lazyParams,
      totalRecords,
      scrollHeight,
      dialstatuses,
      CallsReportsHeaders,
      selectedRecords,
      tabs,
      tabsData,
      tabsDataValues,
      reports,
      endDate,
      startDate,
      activeTab,
      callsFilters,
      callReports,
      tabChangeHandle,
      calltypesOptions,

      onSort,
      onPage,
      onFilter,
      updateDate,
      getCallType,
      loadLazyData,
      filterSearch,
      filterDateSearch,
      clearFilters,
      setPagination,
      getDefaultCalls,
      getDefaultDates,
      getDialedStatus,
      showCalltypeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.calendar-wrapper {
  min-width: 350px;
}
</style>
