<template>
  <div class="overflow-y-hidden flex">
    <!-- <div
    class="sidebar text-black dark:text-off-white grid grid-cols-9 dark:bg-black"
  > -->
    <aside-section />

    <!-- <section class="col-span-8">
      <div class="main text-left">
        <ul class="font-inter">
          <template v-if="v_flow1">
            <ListItem
              :title="$t('label.xpbx.side-menu-bar.body.dashboard')"
              mainClasses="flex itmes-center"
              classes="inline-block flex-grow"
              icon="contact"
              iconClass="-mt-1"
              @click.prevent="$router.push({ name: 'xpbx' })"
              :isActive="route.name == 'xpbx'"
            >
              <template #meta>
                <span></span>
              </template>
            </ListItem>
            <ListItem
              :title="$t('label.xpbx.side-menu-bar.body.users')"
              mainClasses="flex itmes-center"
              classes="inline-block flex-grow"
              icon="sidebar"
              iconClass="-mt-1"
              @click.prevent="$router.push({ name: 'xpbx-users' })"
              :isActive="route.name == 'xpbx-users'"
            ></ListItem>
            <ListItem
              :title="$t('label.xpbx.side-menu-bar.body.numbers')"
              mainClasses="flex itmes-center"
              classes="inline-block flex-grow"
              icon="appearance"
              iconClass="-mt-1"
              @click.prevent="$router.push({ name: 'xpbx-numbers' })"
              :isActive="route.name == 'xpbx-numbers'"
            ></ListItem>
            <ListItem
              :title="$t('label.xpbx.side-menu-bar.body.extensions')"
              mainClasses="flex itmes-center"
              classes="inline-block flex-grow"
              icon="sidebar"
              iconClass="-mt-1"
              @click.prevent="$router.push({ name: 'xpbx-extensions' })"
              :isActive="route.name == 'xpbx-extensions'"
            ></ListItem>
            <ListItem
              :title="$t('label.xpbx.side-menu-bar.body.reports')"
              mainClasses="flex itmes-center"
              classes="inline-block flex-grow"
              icon="multilanguage"
              iconClass="-mt-1"
              @click.prevent="$router.push({ name: 'xpbx-reports' })"
              :isActive="route.name == 'xpbx-reports'"
            ></ListItem>
            <ListItem
              :title="$t('label.xpbx.side-menu-bar.body.settings')"
              mainClasses="flex itmes-center"
              classes="inline-block flex-grow"
              icon="sidebar"
              iconClass="-mt-1"
              @click.prevent="$router.push({ name: 'xpbx-settings' })"
              :isActive="route.name == 'xpbx-settings'"
            ></ListItem>
          </template>
        </ul>
      </div>
    </section> -->
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";
import asideSection from "@/modules/xpbx/components/home/aside-section";
import ListItem from "@/modules/xpbx/components/home/sidebar/Listitem";

export default {
  name: "index",
  components: {
    asideSection,
    ListItem,
  },

  props: {
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const route = useRoute();
    const isDashboardPage = computed(() => {
      return ["virtual-number"].includes(route.name);
    });
    return {
      route,
      isDashboardPage,
      v_flow1: true,
    };
  },
};
</script>

<style scoped>
.main ul > li {
  padding: 0.65rem 0.5rem;
  vertical-align: middle;
}
.sidebar {
  margin-top: 2px;
  height: calc(100vh - 60px);
}
.sidebar .main ul > li:not(:first-of-type) {
  margin-top: 2px;
  height: calc(100vh - 55px);
}
</style>
