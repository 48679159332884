<template>
  <Layout>
    <div class="mt-2">
      <PageHeader :heading="$t('xpbx.pages.heading.reports')" />
    </div>
    <section class="pl-6 pr-5 pb-2 h-full">
      <Tabs :tabs="tabs" :activeTab="activeTab" @tabChange="tabChangeHandle" />
      <CallsTable
        v-if="activeTab === 1"
        @update="update"
        :updateId="activeInitialTab"
      />
      <SmsTable v-if="activeTab === 2" />
    </section>
  </Layout>
</template>

<script>
import { inject, ref, onMounted } from "vue";
import Layout from "@/modules/xpbx/components/home/layout/index";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import Tabs from "@/modules/xpbx/components/UI/tabs/index.vue";
import SmsTable from "@/modules/xpbx/components/reports/sms-table/index.vue";
import CallsTable from "@/modules/xpbx/components/reports/calls-table/index.vue";

export default {
  name: "Home",
  components: {
    Layout,
    PageHeader,
    Tabs,
    SmsTable,
    CallsTable,
  },
  setup() {
    const t = inject("t");
    const activeTab = ref(1);
    const activeInitialTab = ref(0);

    const tabs = [
      {
        id: 1,
        title: t("xpbx.pages.subheadings.calls"),
      },
      {
        id: 2,
        title: t("xpbx.pages.subheadings.sms"),
      },
    ];

    const tabChangeHandle = (id) => {
      activeTab.value = id;
      localStorage.setItem("reporstTab", id);
      activeInitialTab.value = id === 1 ? 0 : null;
    };

    const update = (id) => {
      activeInitialTab.value = id;
    };

    onMounted(() => {
      const tab = localStorage.getItem("reporstTab");

      if (tab) {
        activeTab.value = parseInt(tab);
      }
    });

    return {
      tabs,
      update,
      activeTab,
      activeInitialTab,
      tabChangeHandle,
    };
  },

  onMounted() {
    this.getMainInfo();
  },
  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>
